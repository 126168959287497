import Footer from '../Footer';
import Header from '../Header';
import styles from './Oferta.module.css';
import {Helmet} from "react-helmet";
function  Oferta() {
  return (
       <>
        
      <Helmet>
      <link href="https://fonts.googleapis.com/css2?family=Bad+Script&family=Marmelad&family=Pacifico&family=Tangerine:wght@700&display=swap" rel="stylesheet"/>
      </Helmet>
      <div className={styles.wrapper}>
      <Header/>
      <div className={styles.container}>
<h3>ПУБЛІЧНИЙ ДОГОВІР (ОФЕРТУ) на замовлення, придбання, продаж та доставку товарів</h3>


<p>Цей договір між СПД Гуменюк Наталія Ігорівна , надалі «Продавець» та користувачем послугами інтернет-сайту, надалі — «Замовник», є договором доручення на замовлення, придбання та доставку Товарів та визначає основні умови замовлення, придбання та доставки товарів через інтернет- сайт https://morpho.jewelry Покупець, діючи для придбання Товару, приймає умови цього договору купівлі-продажу товарів на наступних умовах.</p>

<h4>ВИЗНАЧЕННЯ ТЕРМІНІВ</h4>
<p>1.1. Публічна оферта (далі – «Оферта») — публічна пропозиція Продавця, адресована невизначеному колу осіб, укласти з Продавцем договір купівлі-продажу товару дистанційним способом (далі — «Договір») на умовах, що містяться в цій Оферті, включаючи всі Додатки.</p>

<p>1.2. Замовлення – рішення Замовника замовити товар та його доставку, оформлене в інтернет-магазині та/або доручення на придбання та доставку товарів.</p>

<h4>ЗАГАЛЬНІ ПОЛОЖЕННЯ</h4>
<p>2.1. Наведена нижче інформація є офіційною пропозицією (офертою) інтернет-магазину https://morpho.jewelry будь-якій фізичній особі (далі — Покупець) укласти договір купівлі-продажу товарів. Зазначений договір є публічним, тобто, згідно зі статтею 633 Цивільного кодексу України, його умови однакові для всіх покупців.</p>

<p>2.2. Відповідно до статті 642 Цивільного Кодексу України повним та беззастережним прийняттям умов цієї пропозиції (оферти), що підтверджує укладення Договору купівлі-продажу товарів на запропонованих нижче умовах, є факт оформлення та підтвердження замовлення.</p>

<p>2.4. Оформленням Замовлення Покупець підтверджує погодження та безумовне прийняття ним умов цієї пропозиції (оферти).</p>

<p>2.5. Укладаючи Договір (тобто акцептуючи умови цієї Пропозиції (Оферти) шляхом оформлення Замовлення), Покупець підтверджує наступне:</p>
<ul>
<li> Покупець повністю ознайомлений, і згоден з умовами цієї пропозиції (оферти);</li>
<li> він дає дозвіл на збирання, обробку та передачу персональних даних на умовах, визначених нижче у Застереженні щодо збору, обробки та передачі персональних даних, дозвіл на обробку персональних даних діє протягом усього строку дії Договору, а також протягом необмеженого строку після закінчення його дії. Крім цього, укладанням Договору Замовник підтверджує, що він повідомлений (без додаткового повідомлення) про права, встановлені Законом України «Про захист персональних даних», про цілі збору даних, а також про те, що його персональні дані передаються Продавцю з метою виконання умов цього Договору, можливості проведення взаєморозрахунків, а також для отримання рахунків, актів та інших документів. Замовник також погоджується з тим, що Продавець має право надавати доступ та передавати його персональні дані третім особам без будь-яких додаткових повідомлень Замовника, не змінюючи при цьому мету обробки персональних даних. Обсяг прав Замовника як суб’єкта персональних даних згідно із Законом України «Про захист персональних даних» йому відомий і зрозумілий.</li>
</ul>

 
<h4>ЦІНА ТОВАРУ</h4>
<p>3.1. Ціна на кожну позицію товару вказана на сайті Інтернет-магазину.</p>

<p>3.2. Продавець має право в односторонньому порядку змінити ціну на будь-яку позицію товару.</p>

<p>3.3. У разі зміни ціни на замовлений Товар Продавець зобов’язується проінформувати Покупця про зміну ціни Товару.</p>

<p>3.4. Покупець має право підтвердити або анулювати Замовлення на придбання Товару, якщо ціна змінена Продавцем після оформлення Замовлення.</p>

<p>3.5. Зміна Продавцем ціни на сплачений Покупцем Товар не допускається.</p>

<p>3.6. Продавець вказує вартість доставки Товару на сайті Інтернет-магазину або повідомляє Покупцю під час оформлення замовлення Оператором.</p>

<p>3.7. Зобов’язання Покупця з оплати Товару вважаються виконаними з моменту надходження Продавцю коштів.</p>

<p>3.8. Розрахунки між Продавцем та Покупцем за Товар здійснюються способами, вказаними на сайті Інтернет-магазину у розділі «ДОСТАВКА ТА ОПЛАТА ОПЛАТА».</p>

<h4>ОФОРМЛЕННЯ ЗАМОВЛЕННЯ</h4>
<p>4.1. Замовлення Товару здійснюється Покупцем через Оператора за телефоном: +380631964616 або через сервіс сайту Інтернет-магазину https://morpho.jewelry</p>

<p>4.2. При реєстрації на сайті Інтернет-магазину Покупець зобов’язується надати наступну реєстраційну інформацію:</p>

<p>4.2.1. прізвище, ім’я, по-батькові Покупця або зазначеної ним особи (отримувача);</p>

<p>4.2.2. адресу, якою слід доставити Товар (якщо доставка до адреси Покупця);</p>

<p>4.2.3. Адреса електронної пошти;</p>

<p>4.2.4. контактний телефон.</p>

<p>4.3. Найменування, кількість, артикул, ціна обраного Покупцем Товару вказуються у кошику Покупця на сайті Інтернет-магазину.</p>

<p>4.4. Якщо Продавцеві потрібна додаткова інформація, він має право запросити її у Покупця. У разі не надання необхідної інформації Покупцем, Продавець не несе відповідальності за надання якісної послуги Покупцю при купівлі товарів в інтернет-маркеті.</p>

<p>4.5. При оформленні Замовлення через Оператора (п. 4.1 цієї Оферти) Покупець зобов’язується надати інформацію, зазначену в п. 4.2. справжньої Оферти.</p>

<p>4.6. Прийняття Покупцем умов цієї Оферти здійснюється за допомогою внесення Покупцем відповідних даних у реєстраційну форму на сайті Інтернет-магазину або під час оформлення Замовлення через Оператора. Після оформлення Замовлення через Оператора, дані про Покупця реєструються в базі даних Продавця.</p>

<p>4.7. Покупець відповідає за достовірність наданої інформації при оформленні Замовлення.</p>

<p>4.8. Договір купівлі-продажу дистанційним способом між Продавцем та Покупцем вважається укладеним з моменту електронного оформлення замовлення на сервісі сайту інтернет-магазину або видачі Продавцем Покупцю касового чи товарного чека чи іншого документа, що підтверджує оплату Товару.</p>

<h4>ДОСТАВКА І ПЕРЕДАЧА ТОВАРУ ПОКУПЦЮ</h4>
<p>5.1. Способи, порядок та строки доставки товарів вказані на сайті в розділі «ДОСТАВКА ТА ОПЛАТА». Порядок та умови доставки замовленого товару Покупець погоджує з оператором інтернет-маркету у момент оформлення покупки.</p>

<p>5.2. Самовивіз товару:</p>

<p>5.2.1. Після формування заявки покупець може зробити розрахунок та отримати свій товар у відділенні «Нова Пошта»</p>

<p>5.2.2. Право власності та ризик випадкової втрати або пошкодження товару переходить до Замовника або його Представника з моменту отримання товару шляхом та підписання Сторонами товарного чека та/або замовлення (і/або доручення на придбання та доставку товару) на доставку.</p>

<p>5.3. Доставка товару здійснюється власними силами співробітниками інтернет-маркету згідно з умовами доставки, або із залученням третіх осіб (перевізника).</p>

<p>5.4. При отриманні товару Замовник повинен у присутності представника кур’єра перевірити відповідність Товару якісним та кількісним характеристикам (найменування товару, кількість, комплектність, термін придатності).</p>

<p>5.5. Замовник або Представник Замовника під час приймання товару підтверджує своїм підписом у товарному чеку та/або замовлення на доставку товарів, що не має претензій до кількості товару, зовнішнього вигляду та комплектності товару.</p>

<h4>ПОВЕРНЕННЯ ТОВАРУ</h4>
<p>6.1. Замовник має право відмовитися від непідакцизного товару у будь-який час до його передачі, а після передачі непідакцизного товару – у порядку та на умовах, визначених Законом України «Про захист прав споживачів».</p>

<p>6.2. Повернення непідакцизного товару належної якості можливе у разі, якщо збережено його товарний вигляд, споживчі властивості, а також документ, що підтверджує факт купівлі та умови замовлення зазначеного товару.</p>

<p>6.3. Замовник не має права відмовитися від товару належної якості, що має індивідуально визначені властивості, якщо зазначений товар може бути використаний виключно Споживачем, який його придбав, (в т.ч. не стандартні, за бажанням Замовника, розміри та інше). Підтвердженням того, що товар має індивідуально визначені властивості, є відмінність розмірів товару та інших характеристик, що вказані в інтернет-магазині.</p>

<p>6.4. Повернення товару, у випадках, передбачених законом та цим Договором, здійснюється за телефоном, вказаним унизу сайту.</p>

<p>6.5. У разі відмови Покупця від непідакцизного товару належної якості Продавець повертає кошти у розмірі вартості такого Товару, за винятком витрат продавця на доставку товару, що повертається.</p>

<p>6.6. Повернення суми, зазначеної у п.6.5. здійснюється одночасно із поверненням товару.</p>

<h4>ВІДПОВІДАЛЬНІСТЬ СТОРІН </h4>
<p>7.1. Продавець не несе відповідальності за шкоду, заподіяну Покупцю внаслідок неналежного використання Товарів, попередньо замовлених на Сервісі https://morpho.jewelry та придбаних у Продавця.</p>

<p>7.2. Продавець не несе відповідальності за неналежне, несвоєчасне виконання Замовлень та своїх зобов’язань у разі надання Покупцем недостовірної чи неправдивої інформації.</p>

<p>7.3. Продавець та Покупець несуть відповідальність за виконання своїх зобов’язань відповідно до чинного законодавства України та положень цього Договору.</p>

<p>7.4. Продавець або Покупець звільняються від відповідальності за повне або часткове невиконання своїх зобов’язань, якщо невиконання є наслідком форс-мажорних обставин як: війна або військові дії, землетрус, повінь, пожежа та інші стихійні лиха, що виникли незалежно від волі Продавця та/або Покупця після укладання цього договору. Сторона, яка не може виконати своїх зобов’язань, негайно повідомляє про це іншу Сторону.</p>
    
</div>
        </div>
        <Footer/>
       </>
  );
}
export default  Oferta;
